<template>
  <div
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <menu-ver type="user" :id_emp="id_emp" />
    <div class="container m-3">
      <div
        :class="{
          tableheader: !device,
          tableheadermobile: device,
        }"
      >
        <h1>{{ $t("mUser.history.title") }}</h1>
      </div>
      <table class="table" v-if="courses && courses.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mUser.courses.name") }}</th>
            <th scope="col">{{ $t("mUser.courses.theme") }}</th>
            <th scope="col">{{ $t("mUser.courses.calif") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in courses" :key="item.curso_id">
            <td>{{ item.curso_titulo }}</td>
            <td>{{ item.curso_temas }}</td>
            <td>{{ item.encurso_calificacion }}</td>
            <td>
              <button
                class="btn button buttonEdit"
                @click="seeCurse(item.curso_id)"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`menu.vercurso`)"
              >
                <i class="far fa-eye"></i>
              </button>
              <!-- <button
                v-if="verValoracion"
                class="btn button buttonEdit"
                @click="valorarCurso(item)"
                :style="{ backgroundColor: colorCompany }"
                style="margin-right: 5px"
                :data-title="$t(`menu.rate`)"
              >
                <i class="fas fa-crown"></i>
              </button> -->
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>
  <!-- Modal Valor -->
  <div
    class="modal fade"
    id="valorarModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("valor.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <label class="form-label" style="font-size: 20pt">{{
                curso_titulo
              }}</label>
            </div>
            <label>{{ $t(`valor.puntos`) }}</label>
            <div class="mb-3" style="text-align: center">
              <div>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(1)"
                  :style="{ color: colorCrown1 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(2)"
                  :style="{ color: colorCrown2 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(3)"
                  :style="{ color: colorCrown3 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(4)"
                  :style="{ color: colorCrown4 }"
                ></i>
                <i
                  class="fas fa-crown crowns"
                  @click="tomarPunto(5)"
                  :style="{ color: colorCrown5 }"
                ></i>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">{{ $t(`valor.comentario`) }}</label>
              <textarea
                style="text-transform: uppercase"
                rows="7"
                class="form-control"
                id="FormName"
                v-model.trim="comentario"
                maxlength="255"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="submit"
            class="btn-light btnCrud"
            @click="sendValorHistorial"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="cleanForm()"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">          
          <button
            v-if="result==='0'"
            type="button"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForm()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-else
            class="btn button btn-return-result"
            data-bs-target="#valorarModal"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      courses: [],
      course: {},
      id_emp: this.$route.params.idemp,
      curso_id: "",
      curso_titulo: "",
      comentario: "",
      puntos: "",
      colorCrown1: "#a4a9a8",
      colorCrown2: "#a4a9a8",
      colorCrown3: "#a4a9a8",
      colorCrown4: "#a4a9a8",
      colorCrown5: "#a4a9a8",
      result: "",
      verValoracion: true
    };
  },
  created() {
    this.getcourses();
    this.changeBreadCrumb(14);
    this.bigDrop();
    if (this.servidor.includes("comunica")) {
      this.verValoracion = false;
    }
  },
  computed: {
    ...mapState(["servidor", "colorCompany", "device"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=historial&ac=listado`
        );
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    seeCurse(id) {
      let routeUrl = this.$router.resolve({
        path: `/${this.id_emp}/student/mycourse/${id}`,
      });
      window.open(routeUrl.href, "_blank");
    },
    valorarCurso(item) {   
      this.curso_id = item.curso_id;
      this.curso_titulo = item.curso_titulo;
      $("#valorarModal").modal("show");
    },
    cleanForm() {
      this.curso_id = "";
      this.curso_titulo = "";
      this.comentario = "";
      this.puntos = "";
      this.colorCrown1 = "#a4a9a8";
      this.colorCrown2 = "#a4a9a8";
      this.colorCrown3 = "#a4a9a8";
      this.colorCrown4 = "#a4a9a8";
      this.colorCrown5 = "#a4a9a8";
      this.getcourses();
    },
    tomarPunto(num) {
      this.puntos = num;
      switch (num) {
        case 1:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = '#a4a9a8';
          this.colorCrown3 = '#a4a9a8';
          this.colorCrown4 = '#a4a9a8';
          this.colorCrown5 = '#a4a9a8';
          break;
        case 2:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = '#a4a9a8';
          this.colorCrown4 = '#a4a9a8';
          this.colorCrown5 = '#a4a9a8';
          break;
        case 3:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = '#a4a9a8';
          this.colorCrown5 = '#a4a9a8';
          break;
        case 4:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = this.colorCompany;
          this.colorCrown5 = '#a4a9a8';
          break;
        case 5:
          this.colorCrown1 = this.colorCompany;
          this.colorCrown2 = this.colorCompany;
          this.colorCrown3 = this.colorCompany;
          this.colorCrown4 = this.colorCompany;
          this.colorCrown5 = this.colorCompany;
          break;
      }
    },
    async sendValorHistorial() {
      const valor = {
        curso_id: this.curso_id,
        encurso_comentario: this.comentario,
        encurso_valoracion: this.puntos
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=historial&ac=valorar`, valor
        );
        if (res.data) {
          this.result = res.data.RESULT;
        }
        $("#valorarModal").modal("hide");
        $("#responseModal").modal("show");
      } catch (error) {
        $("#valorarModal").modal("hide");
        this.result = "1";
        $("#responseModal").modal("show");
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}

.crowns {
  font-size: 22pt;
  padding: 17pt;
  cursor: pointer;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>